import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Box, HStack, Stack } from "@chakra-ui/react";

const LinkStyles = {
  textDecoration: "none",
  color: "black",
};

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <Box mb={3}>
        <Box>
          <Stack>
            <Box color={"gray.500"} fontSize={"30px"} fontWeight={"500"}>
              <Link to="/" style={LinkStyles}>
                {data.site.siteMetadata.title}
              </Link>
            </Box>
            <HStack spacing={"25px"}>
              <Link to="/home" style={LinkStyles}>
                Home
              </Link>
              <Link to="/about" style={LinkStyles}>
                About
              </Link>
              <Link to="/" style={LinkStyles}>
                blog
              </Link>
              <Link to="/contact" style={LinkStyles}>
                contact
              </Link>
            </HStack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default Header;
